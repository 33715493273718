.wrapper {
    max-width: 100%;
    overflow: hidden;
    background-color: #20bb9e;
    height: 32px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.marquee {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    align-items: center;
    justify-content: center;
    /* animation: marquee 10s linear infinite; */
    height: fit-content;
    /* &:hover {
        animation-play-state: paused;
      } */
}

.marquee a {
    text-align: center;
    display: inline-block;
    margin: 5px 15px;
    color: white;
    font-family: Space Mono;
    height: fit-content;
    text-decoration: none;
}