.header-container {
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: space-between;
    font-family: Space Mono;
    font-weight: 400;
    position:sticky;
    z-index:9999;
    top:0px;
    font-size: 18px;
    background-color: hsla(0, 100%, 100%, .9);
    backdrop-filter: blur(5px) saturate(200%);
    padding: 10px 15px;
    box-shadow: 0px 2px 4px 0px rgba(51, 55, 59, .08), 0px 1px 10px 0px rgba(51, 55, 59, .06);
}
.header-container-mobile {
    width: calc(100% - 30px);
    display: flex;
    flex-direction: row;
    height: fit-content;
    justify-content: space-between;
    font-family: Space Mono;
    font-weight: 400;
    z-index:9999;
    font-size: 18px;
    background-color: white;
    padding: 10px 15px;
}

.header-inside {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-link {
    padding: 10px 25px;
    text-decoration: none;
    color: black;
    height: fit-content;
    transition: 0.2s;
    border-radius: 8px;
}

.header-link:hover {
    background-color: #20bb9e;
    color:white
}

.header-link-special {
    margin-left: 10px;
    padding: 10px 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    background-color: #20bb9e;
    height: fit-content;
}

.header-link-special:hover {
    background-color: rgb(20, 214, 195);
}

.header-link-special:active {
    background-color: rgb(66, 225, 196);
}

/* https://www.w3schools.com/howto/howto_css_menu_icon.asp */
.container {
    display: inline-block;
    cursor: pointer;
    margin-left: 10px;
}

.bar1,
.bar2,
.bar3 {
    width: 35px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.2s;
}

/* Rotate first bar */
.change.bar1 {
    transform: translate(0, 9px) rotate(-45deg);
}

/* Fade out the second bar */
.change.bar2 {
    opacity: 0;
}

/* Rotate last bar */
.change.bar3 {
    transform: translate(0, -9px) rotate(45deg);
}

.mobile-header {
    z-index: 999 !important;
    position: fixed;
    background-color: white;
    top: 0px;
    left: 0px;
    height: 50px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: calc(100% - 20px);
    border-bottom: 1px solid #d6d6d6;
    justify-content: space-between;
}
.menu-container {
    position: fixed;
    background-color: white;
    height: calc(100vh - 64px);
    left: 0;
    top: 64px;
    z-index: 1000;
    padding-top: 100px;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
}
.header-link-mobile {
    font-family: Space Mono;
    text-decoration: none;
    font-weight: 500;
    color: black;
    font-size: 24px;
}
.header-link-mobile:active {
    text-decoration: underline;
    text-decoration-thickness: 2px;
}
.header-link:focus, .header-link:focus-visible {
    outline-offset: 0px;
    z-index: 999;
}
.header-link-special-mobile {
    padding: 10px 30px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    background-color: #20bb9e;
    height: fit-content;
    font-size: 24px;
}

.header-link-special-mobile:hover {
    background-color: rgb(20, 214, 195);
}

.header-link-special-mobile:active {
    background-color: rgb(66, 225, 196);
}
