.bot-p {
    margin: 0;
    margin-top: 5px;
    font-family: 'Open Sans';
    color: black;
    font-weight: 350;
}

.bot-p-config {
    height: min-content;
    margin: 0;
    margin-top: 5px;
    font-family: 'Open Sans';
}
select {
    padding: 10px 25px;
    font-size: 16px;
    border-radius: 6px;
    font-family: Open Sans;
    outline: none;
    background-color: #e6edee;
    border: 1px solid #d6d6d6;
    width: 100%;
    border: 0;
    margin-top: 25px;
}

code {
    padding: 15px 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
    background-color: #eef2f5;
    width: 100%;
    max-width: -webkit-fill-available;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
}

.documentation-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: -webkit-fill-available;
    margin-bottom: 50px;
    max-width: calc(100% - 320px);
}

.main-description {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-left: 20px;
    background-color: white;
    width: calc(100% - 85px);
    padding: 20px;

}

.doc-section-title {
    margin: 0px 0px 10px 0px;
    font-size: 32px;
    font-weight: 500;
    font-family: 'Space Mono';
}

tr,
th,
td {
    height: min-content;
}

th {
    align-items: center;
    justify-content: center;
}

td,
th {
    border-top: 1px solid #e0e0e0;
    border-collapse: collapse;
    padding: 10px;
}

td {
    border-left: 1px solid #e0e0e0;
    height: min-content;
}

bar {
    height: 1px;
    margin: 10px 0px;
    width: 100%;
    background-color: #e4e4e4;
}

.section-title {
    cursor: pointer;
    font-weight: 500;
    color: black;
    text-decoration: none;
    margin: 0;
    font-family: 'Space Mono';
    font-size: 24px;
    transition: 0.2s;
}
.img-doc {
    margin: 40px 0px;
}
.bot-subtitle {
    font-weight: 500;
    font-family: 'Space Mono';
    margin: 0;
    margin-left: 15px;
    margin-bottom: 30px;
}

.section-title-selected {
    cursor: pointer;
    margin: 0;
    font-family: Space Mono;
    font-size: 24px;
    font-weight: 500;
}
th {
    margin-right: -2px;
}
.doc-link {
    color: rgb(32, 187, 158);
    font-weight: 500;
}
.doc-section-title {
    margin: 0px 0px 10px 0px;
    font-size: 32px;
    font-weight: 500;
}

section {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 10px;
    padding-right: 30px;
}

.navmenu {
    position: sticky;
    top: 50px;
    left: 20px;
    display: flex;
    border-radius: 8px;
    flex-direction: column;
    gap: 20px;
    width: 200px;
    padding: 20px;
    height: min-content;
    padding-left: 30px;
    padding-right: 80px;
    height: min-content;
    background-color: white;
}
little-code {
    background-color: #f6f8fa;
    border-radius: 4px;
    padding: 0px 3px;
    font-weight: 400;
    color: black;
    font-family: monospace;
}
.subsection-div {
    display: flex;
    flex-direction: column;
    gap: 5px;
    white-space: nowrap;
    width: fit-content;
}
.subsection-p {
    cursor: pointer;
    margin: 0;
    color: #8b8b8b;
    text-decoration: none;
    padding: 5px 0px;
}
.subsection-p-selected {
    cursor: pointer;
    margin: 0;
    text-decoration: none;
    color: black;
    background-color: #e9eef1;
    padding: 5px 10px;
    border-radius: 6px;
}
.main-bot {
    width: calc(100% - 100px);
    padding: 50px;
    display: flex;
    height: fit-content;
    flex-direction: row;
    align-items: flex-start;
    gap: 20px;
}

.bot-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
iframe {
    width: 100%;
    height: 500px;
}
svg {
    max-width: 100%;
}
@media (max-width: 945px) {
    iframe {
        width:100%;
        height:300px;
    }
    .documentation-div {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: unset;
    }

    .main-bot {
        width: calc(100% - 40px);
        padding: 20px;
        display: flex;
        height: -moz-fit-content;
        height: fit-content;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 73px;
        gap: 20px;
    }

    .navmenu {
        position: relative;
        top: unset;
        left: 25px;
        width: calc(100% - 165px);
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        height: -moz-fit-content;
        height: fit-content;
        padding-left: 30px;
        padding-right: 80px;
        height: min-content;
        border-radius: 8px;
        background-color: white;
    }

    .doc-section-title {
        padding-left: 20px;
    }

    td {
        border: 0;
        height: min-content;
    }

    th {
        height: min-content;
    }

    .main-description {
        padding-right: 0;
        padding-left: 0;
        width: calc(100% - 40px);
    }

    tr {
        height: min-content;
        display: flex;
        flex-direction: column;
        border: 1px solid #dfdfdf;
        border-top: 0;
    }

    tr:first-child {
        border-top: 1px solid #dfdfdf;
    }
}
