@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@font-face {
    font-family: 'pixel';
    src: url("../public/images/pixel.ttf");
}
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');
.home-title {
    font-family: 'Space Mono';
    font-weight: 400;
    font-size: 52px;
}
*:focus-visible, *:focus {
    outline: 3px solid #3adbbd;
    outline-offset: 2px;
}
a {
    border-radius: 6px;
}
body {
    margin: 0;
    font-family: Open Sans;
    min-height: 100vh;
}
html {
    transition: 0.2s;
}
.main-container {
    display: flex;
    flex-direction: column;
}
.success-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Space Mono;
}

h2 {
    font-weight: 500;
    font-size: 16px;
}

p {
    line-height: 30px;
}

.home-p {
    line-height: 40px;
    font-weight: 350;
    font-size: 22px;
    color: rgb(131, 140, 146);
}
label {
    color: rgb(131, 140, 146);
}
textarea {
    outline: none;
    font-family: Open Sans;
    padding: 20px;
    resize: none;
    font-size: 16px;
    border: 1px solid #d5d9d9;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
    border-radius: 6px;
}
input {
    padding: 10px 20px;
    outline: none;
    border: 1px solid #d5d9d9;
    border-radius: 6px;
    font-size: 16px;
    font-family: Open Sans;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
}
input:focus-visible {
    border: 1px solid #b3b7b7;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 3px 6px rgba(0, 0, 0, 0.1);
}
input:disabled {
    background-color: #f1f4f4;
    cursor: not-allowed;
}
p {
    color: rgb(131, 140, 146);
}

.home-link-wow {
    background-color: rgb(32, 187, 158);
    border-radius: 8px;
    box-shadow: rgba(3, 86, 75, 0.2) 0 -25px 18px -14px inset, rgba(3, 86, 75, .15) 0 1px 2px, rgba(3, 86, 75, .15) 0 2px 4px, rgba(3, 86, 75, 0.149) 0 4px 8px, rgba(3, 86, 75, 0.15) 0 8px 16px, rgba(3, 85, 86, .15) 0 16px 32px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: inline-block;
    font-family: 'Open Sans';
    padding: 7px 30px;
    display: flex;
    text-align: center;
    justify-content: center;
    text-decoration: none;
    transition: all 250ms;
    align-items: center;
    font-size: 18px;
    border: 0;
}

.home-link-wow:hover {
    box-shadow: rgba(3, 85, 86, 0.35) 0 -25px 18px -14px inset, rgba(3, 85, 86, .25) 0 1px 2px, rgba(3, 85, 86, .25) 0 2px 4px, rgba(3, 85, 86, .25) 0 4px 8px, rgba(3, 85, 86, .25) 0 8px 16px, rgba(3, 85, 86, .25) 0 16px 32px;
    transform: scale(1.05) rotate(3deg);
}

.home-link {
    outline: none;
    font-family: Open Sans;
    border: 0;
    display: flex;
    justify-content: center;
    padding: 10px 30px;
    text-wrap: nowrap;
    cursor: pointer;
    align-items: center;
    text-align: center;
    font-size: 18px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    border-radius: 8px;
    background-color: #20bb9e;
    height: fit-content;
    transition: 0.1s;
}

.home-link:hover {
    background-color: rgb(22, 194, 179);
}
.home-link:active {
    background-color: #0e9c90;
    box-shadow: inset 0px -10px 10px 0px rgb(12, 159, 144);
}
.home-link:disabled {
    cursor: not-allowed;
    background-color: #dce8e8;
    color: #9fafaf;
    box-shadow: none;
    padding-top: 10px;
    padding-bottom: 10px;
}
.loading-div {
    align-items: center;
    display: flex;
    flex-direction: column;
}
.home-link-basic {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    text-align: center;
    padding: 10px 30px;
    font-size: 18px;
    font-family: Open Sans;
    text-decoration: none;
    color: rgb(0, 0, 0);
    border-radius: 8px;
    background-color: white;
    border: 1px solid #d5d9d9;
    height: fit-content;
    box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
}
.scroll-button {
    background-color: #ffffff;
}
.scroll-button:active {
    background-color: #f6f8f8;
}
.home-link-basic:hover {
    background-color: #f7fafa;
}

.barbotine-robot {
    width: 50%;
}

.home-link-basic:active {
    background-color: #eff1f1;
    box-shadow: rgba(182, 185, 185, 0.5) 0 2px 5px 0;
}

.first-card {
    margin-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.second-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
    color: white;
    padding-top: 100px;
    background-image: url('../public/images/background.svg');
    background-size: cover;
}
.second-form-div {
    padding-right: 50px;
}
.second-card-wrap1 {
    grid-column: 1;
    grid-row: 1;
    background-color: #0000001b;
    backdrop-filter: blur(5px);
    padding: 5px 15px;
    border-radius: 6px;
    border: 0.5px solid #0000002d;
    outline: 0.5px solid #ffffff2d;
}

.second-card-wrap2 {
    grid-column: 2;
    grid-row: 1;
    background-color: #0000001b;
    backdrop-filter: blur(5px);
    padding: 5px 15px;
    border-radius: 6px;
    border: 0.5px solid #0000002d;
    outline: 0.5px solid #ffffff2d;
}

.second-card-wrap3 {
    grid-column: 3;
    grid-row: 1;
    background-color: #0000001b;
    backdrop-filter: blur(5px);
    padding: 5px 15px;
    border-radius: 6px;
    border: 0.5px solid #0000002d;
    outline: 0.5px solid #ffffff2d;
}

.second-card-wrap4 {
    grid-column: 1;
    grid-row: 2;
    background-color: #0000001b;
    backdrop-filter: blur(5px);
    padding: 5px 15px;
    border-radius: 6px;
    border: 0.5px solid #0000002d;
    outline: 0.5px solid #ffffff2d;
}

.second-card-wrap5 {
    grid-column: 2;
    grid-row: 2;
    background-color: #0000001b;
    backdrop-filter: blur(5px);
    padding: 5px 15px;
    border-radius: 6px;
    border: 0.5px solid #0000002d;
    outline: 0.5px solid #ffffff2d;
}

.second-card-wrap6 {
    grid-column: 3;
    grid-row: 2;
    background-color: #0000001b;
    backdrop-filter: blur(5px);
    padding: 5px 15px;
    border-radius: 6px;
    border: 0.5px solid #0000002d;
    outline: 0.5px solid #ffffff2d;
}

.second-card-wrapper {
    padding: 100px;
    display: grid;
    grid-gap: 30px;
}

.second-card-div1 {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.first-card-div1 {
    background-color: white;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 55%;
    padding: 5%;
    padding-top: 100px;
}

.first-card-div2 {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    justify-content: center;
}

numero {
    border-radius: 500px;
    background-color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #d5d9d9;
    /* box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0; */
}

.step-title {
    margin-bottom: 0px;
    font-family: Open Sans;
}

.one-half-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

.one-half-card-div2 {
    width: calc(100% - 100px);
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 25px;
    border-radius: 16px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.one-half-card-grid {
    display: grid;
    width: fit-content;
    grid-gap: 50px;
    text-align: center;
}

.step1 {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
}

.step2 {
    grid-column: 2;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
}

.step3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
}

.step4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
}

.step5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
}

.step6 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 350px;
}

.final-step {
    grid-column: 1/3;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: center;
}

.final-time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: white;
    padding: 10px 20px 10px 15px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 500px;
    background-color: rgb(32, 187, 158);
    width: fit-content;
}

.one-half-card-div1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 100px;
    width: fit-content;
}

.first-card-buttons-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    gap: 30px;
    margin-top: 40px;
}

b {
    font-weight: 650;
}

.home-link-text {
    text-decoration: none;
    color: black;
}

.home-link-text:hover {
    text-decoration: underline;
    text-decoration-thickness: 2px;
}

.third-card {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 100px;
    background-color: rgb(240, 243, 246);
}

.third-card-div1 {
    width: calc(55% - 200px);
    padding: 100px;
}

.third-card-div2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: fit-content;
    padding: 100px;
}
.main-pricing {
    width: calc(100% - 200px);
    padding: 100px;
}
.pricing-header {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.pricing-container {
    display: flex;
    margin-top: 100px;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    justify-content: center;
}
.payment-container {
    opacity: 0;
    width: 70%;
    z-index: -1;
    padding: 20px;
    position: fixed;
    border: 1px solid #d5d9d9;
    padding: 0px;
    border-radius: 8px;
    transform: translateX(100%);
    transition: opacity 0.4s ease, transform 0.3s ease;
}
.payment-container.active {
    opacity: 1;
    z-index: 40;
    padding: 20px;
    position: relative;
    transform: translateX(0px);
}
.form-container {
    flex-direction: row;
    display: flex;
    gap: 50px;
}
.return-button {
    background-color: white;
    outline: none;
    border: 0;
    padding: 10px 25px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-size: 16px;
    font-family: Open Sans;
    border-radius: 8px;
    margin-bottom: 50px;
    transition: 0.1s;
}
.return-button:hover {
    background-color: #eef0f3;
    padding-left: 15px;
    /* padding-right: 30px; */
}
.form-input-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.first-form-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    min-width: 285px;
    max-width: 580px;
}
.contact-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 350px;
    align-self: center;
    margin-top: 50px;
}
.offers-container {
    display: grid;
    position: fixed;
    z-index: -1;
    width: fit-content;
    align-items: center;
    justify-content: center;
    grid-template-columns: 50% 50%;
    gap: 50px;
    height: min-content;
}
.offers-container.active {
    position: relative;
    z-index: 40;
}
.tick-icon {
    opacity: 0;
    transition: 0.2s;
}
.tick-icon.selected {
    opacity: 1;
}
button, Link, a {
    cursor: pointer;
}
.price1, .price2, .price3 {
    max-width: calc(350px - 50px);
    height: -webkit-fill-available;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    border: 1px solid #d5d9d9;
    border-radius: 8px;
    min-width: 250px;
}
.price1 {
    grid-column: 1;
}
.price2 {
    grid-column: 2;
}
.price3 {
    grid-column: 2;
}
.features-list {
    text-align: left;
    margin-top: 30px;
}
.price-header {
    margin-bottom: 5px;
    padding-top: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
}
.payment-form {
    display: flex;
    flex-direction: column;
}
.feature {
    margin:10px 0px
}
.pricing-title {
    color: rgb(131, 140, 146);
    font-weight: 400;
    font-size: 20px;
}
.pricing-price {
    font-size: 48px;
    margin-left: -15px;
    margin-bottom: 0px;
    margin-top: 30px;
}
.legal-title-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    width: calc(100% - 50px);
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
    padding: 25px;
}
.legal-div {
    border: 1px solid #dde4e8;
    border-radius: 8px;
    width: 75%;
    align-self: center;
    margin-bottom: 50px;
}
.legal-content {
    transition: max-height 0.6s ease, padding 0.3s ease, opacity 0.3s ease;
    max-height: 0px;
    opacity: 0;
    padding: 0px;
}
.legal-content.opened {
    max-height: 5000px;
    opacity: 1;
    padding: 25px;
    padding-top: 0;
}
.hover-div {
    background-color: white;
    padding: 10px 15px;
    box-shadow: 0px 8px 32px 0px rgba(128, 138, 157, 0.24), 0px 1px 2px 0px rgba(128, 138, 157, 0.12);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: fit-content;
}
.chart-container {
    width: 80%;
    margin-bottom: 200px;
}
.chart-p {
    width: 50%;
}
.hover-value {
    font-family: Space Mono;
    color: black;
    margin: 0;
}
.hover-label {
    font-family: Space Mono;
    margin: 0;
    color: rgb(131, 140, 146);
    font-size: 12px;
}
@media (max-width: 1400px) {
    .price1, .price2, .price3 {
        width: 85%;
    }
    .price1.selected, .price2.selected, .price3.selected {
        width: 85%;
    }
}
@media (max-width: 1060px) {
    .chart-container {
        width: 95%;
    }
    .chart-p {
        width: 80%;
    }
    .main-pricing {
        padding-left: 50px;
        padding-right: 50px;
        align-items: center;
        justify-content: center;
        justify-self: center;
        align-self: center;
    }
    .pricing-container {
        flex-direction: column;
    }
    .offers-container, .payment-container {
        width: 100%;
    }
    .offers-container {
        grid-template-columns: 60%;
    }
    .price1,.price3, .price2 {
        width: 100%;
        justify-self: center;
        grid-column: 1;
    }
}
@media (max-width:856px) {

    .home-title {
        font-size: 42px;
    }

    .first-card-div1 {
        width: 75%;
    }

    .home-p {
        font-size: 20px;
        line-height: 35px;
    }

    .first-card-buttons-row {
        flex-direction: column;
    }

    .home-link-wow {
        padding: 12px 30px;
        font-size: 18px;
    }

    .second-card {
        margin-top: calc(10% + 200px);
    }

    .second-card-div1 {
        width: calc(100% - 100px);
        text-align: left;
        padding: 50px;
    }

    .second-card-wrapper {
        grid-gap: 25px;
        width: calc(100% - 100px);
        padding: 50px;
        text-align: left;
    }

    .second-card-wrap2 {
        grid-column: 1;
        grid-row: 2;
    }

    .second-card-wrap3 {
        grid-column: 1;
        grid-row: 3;
    }

    .second-card-wrap4 {
        grid-column: 1;
        grid-row: 4;
    }

    .second-card-wrap5 {
        grid-column: 1;
        grid-row: 5;
    }

    .second-card-wrap6 {
        grid-column: 1;
        grid-row: 6;
    }

    .one-half-card-div2 {
        width: calc(100% - 100px);
        text-align: left;
        padding: 50px;
    }

    .one-half-card-grid {
        width: -webkit-fill-available;
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;
    }
    .one-half-card-div1 {
        width: calc(100% - 100px);
        padding: 50px;
    }

    .step1 {
        width: fit-content;
        justify-self: center;
    }

    .step2 {
        grid-column: 1;
        grid-row: 2;
        width: fit-content;
        justify-self: center;
    }

    .step3 {
        grid-column: 1;
        grid-row: 3;
        width: fit-content;
        justify-self: center;
    }

    .step4 {
        grid-column: 1;
        grid-row: 4;
        width: fit-content;
        justify-self: center;
    }

    .step5 {
        grid-column: 1;
        grid-row: 5;
        width: fit-content;
        justify-self: center;
    }

    .step6 {
        grid-column: 1;
        grid-row: 6;
        width: fit-content;
        justify-self: center;
    }

    .final-step {
        grid-column: 1;
        justify-self: center;
    }

    .final-time {
        text-wrap: nowrap;
    }

    .third-card {
        width: 100%;
        flex-direction: column;
        gap: 0px;
    }

    .third-card-div1 {
        padding: 50px;
        width: calc(100% - 100px);
    }
    .third-card-div2 {
        padding: 50px;
        padding-top: 0px;
        padding-bottom: 100px;
        justify-content: flex-start;
        width: calc(100% - 100px);
    }
    .main-pricing {
        padding-left: 50px;
        padding-right: 50px;
        align-items: center;
        justify-content: center;
        justify-self: center;
        align-self: center;
        width: calc(100% - 50px);
    }
    .pricing-container {
        flex-direction: column;
    }
    .offers-container, .payment-container {
        width: calc(100% - 50px);
    }
    .offers-container {
        grid-template-columns: 90%;
    }
    .main-pricing {
        padding: 0px 20px;
    }
    .price1,.price3, .price2 {
        width: 100%;
    }
}

@media (max-width:700px) {
    .form-container {
        flex-direction: column;
    }
}
@media (prefers-color-scheme: dark) {
    html {
        background: black;
        filter:invert(1) hue-rotate(180deg) contrast(1.1) brightness(1.2);
    }
    .second-card {
        filter:invert(1) hue-rotate(180deg) brightness(1.3);
    }
    body {
        background: black;
    }
    iframe {
        filter: invert(1), hue-rotate(180deg);
    }
    * {
        box-shadow: none;
    }
}
@media (prefers-color-scheme: light) {
    html {
        background: white;
        filter:invert(0);
    }
    body {
        background-color: white;
    }
    .second-card {
        filter:invert(0) hue-rotate(0deg) brightness(1);
    }
}