.footer-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: calc(100% - 90px);
    align-items: center;
    font-family: Open Sans;
    border-top: 1px solid #dcdcdc;
    color: #bbbbbb;
    font-weight: 300;
    padding: 10px 60px 10px 30px;
    z-index: 999 !important;
    background-color: white;
}
.logo-container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
}
@media (max-width: 768px) {
    .footer-container {
        padding-top: 25px;
        flex-direction: column;
        gap: 10px;
    }
    .copyright-text {
        font-size: 14px;
    }
}